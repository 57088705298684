import React from 'react';
import './Licenses.css'
import { useTranslation } from 'react-i18next';
import licensesImage from '../img/licenses.jpg'

const Licenses = () => {

    const { t } = useTranslation();

    return (
        <div className="licenses" id="licenses">
            <div className="licenses-info">
                <div className="licenses-content">
                    <h1>{t('Licenses.Title')}</h1>
                    <h4>{t('Licenses.Intro1')}</h4>
                    <h4>{t('Licenses.Intro2')}</h4>
                </div>
            </div>
            <div className="licenses-content-image">
                <img src={licensesImage} id="licenses-image"/>    
            </div>
        </div>
    );
}

export default Licenses;