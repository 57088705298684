import NavBar from './components/NavBar'
import Footer from './components/Footer'
import './App.css';
import { BrowserRouter as Router, Switch, Route, HashRouter } from 'react-router-dom';
import ContactUs from './components/ContactForm';
import Migration from './components/Migration';
import Support from './components/Support';
import Licenses from './components/Licenses';
import AboutUs from './components/AboutUs';

function App() {
  
  return (
    <div>
      <HashRouter>
        <NavBar/>
        <div className="App">
            <Switch>
              <Route path="/" exact>
                <AboutUs/>
              </Route>
              <Route path="/services">
                <Support />
                <Migration />
               <Licenses />
              </Route>
              <Route path="/contact">
                <ContactUs/>
              </Route>
            </Switch>
            <Footer />
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
