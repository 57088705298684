import React from 'react';
import './Support.css';
import { useTranslation } from 'react-i18next';
import supportImage from '../img/support.jpg'

const Support = () => {
    const { t } = useTranslation();
    return (
        <div className="support" id="support">
            <div className="support-info">
                <div className="support-content">
                    <h1>Support</h1>
                    <h4>{t('Support.Intro1')}</h4>
                    <h4>{t('Support.Intro2')}</h4>
                    <br></br>
                    <h4><b>{t('Support.Question1')}</b></h4>
                    <h4>{t('Support.Answer1')}</h4>
                    <br></br>
                    <h4><b>{t('Support.Question2')}</b></h4>
                    <h4>{t('Support.Answer2')}</h4>
                </div>
            </div>
            <div className="support-content-image">
                <img src={supportImage} id="support-image"/>    
            </div>
        </div>
    );
}

export default Support;