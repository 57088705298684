import emailjs from "emailjs-com";
import React from 'react';
import './Form.css';
import { useTranslation } from 'react-i18next';

export default function ContactUs() {

    const { t } = useTranslation();

    function sendEmail(e) {

        e.preventDefault();

        emailjs.sendForm('service_99lraoh', 'template_y3ywcj6', e.target, 'user_jGPcW6xQR5KJ9U3SwB5mh')
        .then(function (response) {
            if (response.status == 200 && response.text == 'OK')
                alert(t('MailResponse.Success'));
            else
                alert(t('MailResponse.Failure'));
        }, function (error) {
            alert(t('MailResponse.Failure'));
        });
        e.target.reset();
    }

    return(
        <div className="area">
            <div className="area-content">
                <h1>{t('Navigation Bar.Contact')}</h1>
                <h4>{t('ContactUs.Intro1')}
                <br></br>
                {t('ContactUs.Intro2')} <a href="mailto:info@frokendev.se">info@frokendev.se</a></h4>
            </div>
            <div className="form-box">
                <form name="mailForm" onSubmit={sendEmail}>
                    <input required type="text" placeholder={t('ContactForm.Name')} name="name"/>
                    <input required type="email" placeholder={t('ContactForm.Email')} name="email"/>
                    <input required type="text" placeholder={t('ContactForm.Subject')} name="subject"/>
                    <textarea required cols="30" rows="8" placeholder={t('ContactForm.Message')} name="message"></textarea>
                    <button type="submit" id="submitBtn" value="Send Message">{t('ContactForm.Send')}</button>
                </form>
                <div className="area-content">
                    <h4>Fröken DEV AB
                    <br></br>    
                    Org nr: 559324-7884
                    <br></br>
                    Krankajen 42
                    <br></br>
                    211 12 Malmö, {t('Country.Sweden')}
                    <br></br>
                    {t('ContactUs.Phone')}: +46 739 790473
                    </h4>
                </div>
            </div>
        </div>
    )
}
