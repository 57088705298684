import React, { useRef } from 'react'
import logo from '../logo.svg'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Flag from 'react-world-flags';
import './Flags.css'
import 'bootstrap/dist/css/bootstrap.css'
import './Navbar.css'
import { HashLink } from "react-router-hash-link";

const NavBar = () => {

    const { t } = useTranslation();
    
    function handleClick(lang) {
        i18next.changeLanguage(lang);
    }

    const toggleRef = useRef(null);

    const hideNavBar = () => {
        if(window.innerWidth < 1200){
            toggleRef.current.click();
        }
    }
    
    const scrollToTopAndHideNavBar = () => {
        if(window.innerWidth < 1200){
            toggleRef.current.click();
        }
        window.scrollTo(0, 0)
    }

    return (
        <Navbar collapseOnSelect className="nav" fixed="top" expand="xl">
        <Navbar.Brand>
          <HashLink smooth to="/" onClick={scrollToTopAndHideNavBar}><img src={logo} id="nav-brand"/></HashLink>
        </Navbar.Brand>

        <Navbar.Toggle id="nav-toggle" ref={toggleRef} />
        <Navbar.Collapse>
          <Nav id="nav-menu">
            <HashLink id="nav-link" to='/' onClick={scrollToTopAndHideNavBar}>
                {t('Navigation Bar.About')}
            </HashLink>
            <NavDropdown id="nav-link" title={t('Navigation Bar.Services')}>
                <HashLink id="nav-hashlink" smooth to="/services/#support" onClick={hideNavBar}>Support</HashLink>
                <HashLink id="nav-hashlink" smooth to="/services/#migration" onClick={hideNavBar}>{t('Migrations.Title')}</HashLink>
                <HashLink id="nav-hashlink" smooth to="/services/#licenses" onClick={hideNavBar}>{t('Licenses.Title')}</HashLink>
            </NavDropdown>
            <HashLink id="nav-link" to='/contact' onClick={scrollToTopAndHideNavBar} >
                {t('Navigation Bar.Contact')}
            </HashLink>
            <div className="flagButton">
                <button onClick={()=>handleClick('en')} >
                    <Flag code={ "GB" } height="12px"/>
                </button>
                <button onClick={()=>handleClick('swe')} >
                    <Flag code={ "SE" } height="12px"/>
                </button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

export default NavBar