import React from 'react'
import logo from '../logo_only_face.svg'
import "./Footer.css";
import { useTranslation } from 'react-i18next';

function Footer() {

    const { t } = useTranslation();

    return (
        <div className="main-footer">
            <div className="container">
                <div className="row">
                    <img src={logo} />
                    <hr />
                </div>
                <div className="row">
                    <p className="footer-text">
                        &copy;{new Date().getFullYear()} Fröken DEV AB, Org nr: 559324-7884 | Krankajen 42, 211 12 Malmö, {t('Country.Sweden')} | +46 739 790473
                    </p>
                </div>
            </div>
        </div>
  );
}

export default Footer;