import React from 'react';
import './Migration.css'
import { useTranslation } from 'react-i18next';
import migrationImage from '../img/migration.jpg';

const Migration = () => {

    const { t } = useTranslation();
    
    return (
        <div className="migration" id="migration">
            <div className="migration-info">
                <div className="migration-content">
                    <h1>{t('Migrations.Title')}</h1>
                    <h4><b>{t('Migrations.Question1')}</b></h4>
                    <h4>{t('Migrations.Answer1')}</h4>
                    <br></br>
                    <h4><b>{t('Migrations.Question2')}</b></h4>
                    <h4>{t('Migrations.Answer2')}</h4>
                </div>
            </div>
            <div className="migration-content-image">
                <img src={migrationImage} id="migration-image"/>    
            </div>
        </div>
    );
}

export default Migration;