import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css'

const AboutUs = () => {
    
    const { t } = useTranslation();

    return (
        <div className="about-us">
            <div className="about-us-info">
                <div className="wave">
                    <div className="about-us-content">
                        <h1> {t('AboutUs.Title')}</h1>
                        <h4>
                            {t('AboutUs.Introduction')}
                            <br></br>
                            <br></br>
                            <b>{t('AboutUs.Offer')}</b>
                            <br></br>
                            <br></br>
                            {t('AboutUs.Contact')}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
              